define(['app', 'siteObj'], (app, siteObj) => {
  const PowerReviewStars = () => {

    const component = {};

    const COMPONENT_ID_PREFIX = 'pr-category-snippet-'
    const COMPONENT_ID_PREFIX_PDP =  'pr-review-snippet-'
    const PRODUCT_PAGE_TYPE = 'product'

    const _selectors = {
      reviewsSection: '.athenaProductPage_powerReviewDisplay',
      westendHeader: '.westendHeader',
      scrollToReview: 'data-scroll-to-reviews'
    };

    const _subscribeChannels = {
      variantChange: 'productReviewStars/update',
    };

    const _init = (element) => {
      component.element = element;
      component.bind();
      component.subscribe();
    };

    const _bind = () => {
      if(component.element.hasAttribute(component.selectors.scrollToReview)){
        component.element.addEventListener('click', component.scrollToReviews);
      }
    }

    const _subscribe = () => {
      if(siteObj.type === PRODUCT_PAGE_TYPE){
        const isProductBlock = component.element.hasAttribute('data-is-product-block');
        if (!isProductBlock) {
          app.subscribe(component.subscribeChannels.variantChange, function (sku) {
            component.updateComponentId(sku);
          });
        }
      }
    };

    const _updateComponentId = (sku) => {
      component.element.id = `${siteObj.type === PRODUCT_PAGE_TYPE ? COMPONENT_ID_PREFIX_PDP : COMPONENT_ID_PREFIX}${sku}`;
    }

    const _scrollToReviews = () => {
      const westendHeader = document.querySelector(component.selectors.westendHeader);
      const reviewsSection = document.querySelector(component.selectors.reviewsSection);
      if(reviewsSection){
        app.element.scrollTo(reviewsSection.offsetTop - (westendHeader ? westendHeader.clientHeight : 0), 500);
      }
    }

    component.init = _init;
    component.bind = _bind;
    component.selectors = _selectors;
    component.subscribe = _subscribe;
    component.subscribeChannels = _subscribeChannels;
    component.updateComponentId = _updateComponentId;
    component.scrollToReviews = _scrollToReviews;

    return component;
  };
  return PowerReviewStars;
});
